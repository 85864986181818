body {
	background-color: aliceblue !important;
	position: relative;
	margin: 0;
	padding-bottom: 6rem;
	min-height: 100%;
}

html {
	height: 100%;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

.footer {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1rem;
	background-color: #efefef;
	text-align: center;
}

.logintitle {
	align-items: center !important;
	display: flex !important;
	margin: 8px !important;
	font-size: 13px !important;
	color: #000 !important;
	text-decoration: none !important;
	font-weight: 600 !important;
}

.logintitle:hover {
	text-decoration: none !important;
	color: #2357f3 !important;
}

.logintitle .lgsgicon {
	color: #f00 !important;
	display: none !important;
}

.profileimg {
	align-items: center !important;
	display: flex !important;
	margin: 10px 10px !important;
	font-size: 10px !important;
	width: 30px !important;
	height: 30px !important;
	cursor: pointer;
}

.iconapp:hover {
	background-color: transparent !important;
}

.getpremium {
	position: relative;
	top: 16px;
}
.getprem {
	display: none !important;
}
.getprem1 {
	display: block;
}

.iconapp svg {
	font-size: 22px !important;
	margin-right: 10px;
	color: #000;
}

.iconapp svg:hover {
	color: #2357f3;
}

.menuheading {
	font-size: 16px !important;
	color: #cbcbcb;
	margin-left: 15px !important;
	margin-bottom: 14px !important;
	margin-top: 10px;
}

.menuitem li a {
	font-size: 13px !important;
	text-decoration: none;
	color: #000;
	position: relative;
	font-weight: 500 !important;
}

.menuitem li a span {
	position: relative;
	top: -7px;
}

.menuitem .MuiListItem-button:hover {
	background-color: transparent !important;
}
.appnavbar {
	background: #fff !important;
	box-shadow: 0px 0px 0px 0px !important;
	box-shadow: 0 2px 60px rgba(0, 0, 0, 0.35) !important;
}

.iconimg {
	margin-right: 10px;
	width: 25px;
}

.menuitem .MuiPopover-paper {
	border-radius: 8px !important;
	box-shadow: 0 0px 5px rgb(0 0 0 / 20%) !important;
}

.menuitem .MuiPopover-paper {
	top: 60px !important;
	padding: 5px 10px !important;
	width: auto !important;
	right: 0 !important;
	left: 5px !important;
}

.menuitem .MuiPopover-paper {
	top: 72px !important;
	padding: 29px 13px !important;
	width: 450px !important;
	right: 0px !important;
	left: auto !important;
	border-radius: 6px !important;
	max-height: auto !important;
}
.useraddicon {
	display: none !important;
}
.signicons {
	display: block !important;
}

.grow {
	flex-grow: 1;
}
.sectionDesktop {
	display: flex;
}

@media (max-width: 767px) {
	.logintitle .lgsgicon {
		display: block !important;
	}
	.logintitle .nm {
		display: none !important;
	}
	.getprem {
		display: block !important;
	}
	.getprem1 {
		display: none !important;
	}
}

@media (max-width: 500px) {
	.logodesk {
		display: none !important;
	}
	.logomob {
		display: block !important;
	}
}

/* J end */

.dpalogo {
	height: 56px;
	top: 2px;
	position: relative;
}

.logodesk {
	display: block;
}
.logomob {
	display: none;
}

.mainnavbar {
	background-color: #ffffff !important;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05) !important;
}

/* login page start */

.login_bg {
	background-image: url(asset/images/features_background_img.svg);
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: top;
}

.lgpage {
	padding: 10% 10%;
}

.login_heading {
	font-size: 18px !important;
	font-weight: 600 !important;
	margin-bottom: 34px !important;
}

.pb {
	margin-bottom: 20px;
	padding-left: 10px;
}
.al_right {
	text-align: right;
}

.al_left {
	text-align: left;
}

.al_center {
	text-align: center;
}

.alltxfield .MuiFormLabel-root {
	font-size: 13px !important;
}

.alltxfield .MuiInputBase-root {
	font-size: 14px !important;
	color: #000 !important;
}

.alltxfield .MuiFilledInput-input {
	padding: 20px 12px 10px !important;
}

.alltxfield .MuiInputLabel-filled.MuiInputLabel-shrink {
	transform: translate(12px, 5px) scale(0.75) !important;
}

.alltxfield .MuiFilledInput-root {
	background-color: #f7f8f7 !important;
}

.r_bttn {
	border-radius: 20px !important;
	background: #fb7f2f !important;
}

.p_bttn {
	border-radius: 20px !important;
	background: #01cb99 !important;
}

.g_bttn {
	border-radius: 20px !important;
	background: #bdbdbd !important;
}

.o_bttn {
	border-radius: 20px !important;
}

.googlelogo {
	height: 50px;
	text-align: center;
	margin: 0 auto;
}

.acc_text {
	font-size: 13px !important;
	text-align: center;
	margin-top: 10px !important;
	font-weight: 400 !important;
}

.acc_text a {
	font-size: 13px !important;
	color: #fb7f2f;
	text-decoration: none;
	font-weight: 600;
}

/* Home page section */

.banner {
	background-color: #ffffff;
	height: 100vmin;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	width: 40%;
	margin: 0 auto;
	background-image: url(asset/images/features_background_img.svg);
	background-repeat: no-repeat;
	background-position: top right;
	overflow: hidden;
	width: 100%;
}

.bannerheading {
	color: #181830 !important;
	font-size: 53px !important;
	font-weight: 800 !important;
	line-height: 60px !important;
	position: relative;
	top: 27%;
	left: 8%;
	font-family: "Raleway", sans-serif !important;
}

.otherheading {
	color: #181830 !important;
	font-size: 32px !important;
	font-weight: 800 !important;
	line-height: 40px !important;
	font-family: "Raleway", sans-serif !important;
	margin-bottom: 36px !important;
}

.paragraph {
	font-size: 14px !important;
	text-align: justify;
	line-height: 22px !important;
	color: #242536 !important;
}

.bannerimg {
	height: 100vh;
	position: relative;
	top: 80px;
}

img.bannerimg {
	-webkit-animation: mover 1s infinite alternate;
	animation: mover 1s infinite alternate;
}
img.bannerimg {
	-webkit-animation: mover 1s infinite alternate;
	animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-50px);
	}
}
@keyframes mover {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-50px);
	}
}

.aboutusimage {
	width: 100%;
	position: relative;
	top: -5%;
}

.animation {
	width: 100%;
	position: relative;
}

.sec {
	padding: 5% 0%;
}

.accordianbg {
	background-color: #ffffff;
	padding: 10px;
	border-radius: 6px;
}

.accordianhead {
	font-size: 13px !important;
	font-weight: 500 !important;
}

.accordianbg .MuiAccordionSummary-root {
	display: flex;
	padding: 0px 16px;
	min-height: 48px;
	transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	background: #f5f5f5 !important;
	border-radius: 4px !important;
}

.accordianbg .MuiAccordionSummary-root.Mui-expanded {
	min-height: 64px;
	background-color: #01ca99 !important;
	color: #fff !important;
}

.accordianbg .MuiAccordion-root {
	margin-bottom: 10px !important;
	box-shadow: none !important;
}

.c {
	text-align: center !important;
}
.r {
	text-align: right !important;
}
.l {
	text-align: left !important;
}

.benefitbx {
	background: #f4f4f4;
	padding: 20px;
}

.benefitdata {
	color: #04bda5;
	text-align: center;
	font-weight: 500 !important;
	margin-top: 20px !important;
}

.benefiticon {
	color: #fb7f2f;
	text-align: center;
	font-weight: 500 !important;
	margin-top: 20px !important;
	font-size: 50px !important;
}

.capabilities_bg {
	background-image: url(asset/images/features_background_img.svg);
	background-repeat: no-repeat;
	background-position: center center;
}

/* Home page section */

/* Footer start */

/* .footer{
  padding: 2% 0px;
  } */

.footer_one_text {
	color: #242536;
	text-align: left;
	font-weight: 500 !important;
	font-size: 13px !important;
}

.footer_two_text {
	color: #b4b4b4;
	text-align: right;
	font-weight: 500 !important;
	font-size: 13px !important;
}

/* Footer End */

/* Summarizer page start */

.al_right {
	text-align: right;
}
.al_left {
	text-align: left;
}
.al_center {
	text-align: center;
}

.c1 {
	background-color: #b7acac;
}
.c2 {
	background-color: #d6d0d0;
}

.codingpagestart {
	margin-top: 100px;
}

.sumbg {
	background-color: #ffffff;
	padding: 24px 18px;
	border-radius: 6px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
		rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0 12) 0px 1px 8px 0px;
}

.sumbg .grd {
	background-color: darkslategray;
}

.inputfile {
	display: none !important;
}

.bxheading {
	font-size: 15px !important;
	color: #277abd;
	font-weight: 600 !important;
	text-transform: capitalize !important;
	line-height: 40px !important;
}

.ohsmbg {
	background-color: #efefef;
}

.tt .MuiInputBase-inputMultiline {
	font-size: 13px !important;
}

.textfield .MuiSelect-selectMenu {
	font-size: 13px !important;
}

.textfield .MuiInputLabel-outlined {
	font-size: 13px !important;
}

.textfield .MuiFormControl-root {
	width: 100%;
}

.textfield .MuiInputLabel-formControl {
	font-size: 13px !important;
}

.MuiMenuItem-root {
	font-size: 13px !important;
}

.ic_bt .MuiIconButton-root {
	position: relative;
	top: 0px;
}

.ic_bt_r {
	text-align: right;
}

.ic_bt_r .MuiIconButton-root {
	position: relative;
	top: 0px;
}

.ic_bt_r .MuiSlider-markLabel {
	font-size: 10px !important;
}

.bt_text {
	font-size: 13px !important;
	color: #3c3c3c;
}
.input_bt {
	text-align: right;
}

.bt_button .bt {
	margin-right: 5px;
}

.sideicon {
	position: relative;
	text-align: right;
}

.bottomtext {
	font-size: 12px !important;
	color: #000;
	text-align: justify;
}

/* Summarizer page End */

/*Audio to Text*/

.dpacloud_table {
	margin-bottom: 50px !important;
}

.dpacloud_table .MuiToolbar-regular {
	padding: 0px !important;
}

.bt_main {
	margin-bottom: 50px !important;
}

.dpacloud_table .MuiTableCell-root {
	color: #000 !important;
	font-size: 13px !important;
	padding: 16px 12px !important;
}

.dpacloud_table th {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	min-width: 150px !important;
}

.dpacloud_table .MuiPaper-root {
	padding: 5px !important;
}
.dpacloud_table .MuiTablePagination-root:last-child {
	border: none !important;
}
.MTableToolbar-root-16 {
	padding-right: 8px;
}
.dpacloud_table .MuiInputBase-input {
	height: 10px;
	font-size: 13px !important;
	color: #303f9f !important;
}

.dpacloud_table .MTableHeader-header-27 {
	background-color: #f0f8ff !important;
}

.dpacloud_table .MuiTable-root {
	margin-top: 12px !important;
}

.dpacloud_table .MuiTypography-h6 {
	font-size: 16px !important;
	color: #fb7f2f !important;
}

.upload_bg {
	padding: 30px;
	min-height: 320px;
}

.upload_bg .middleportion {
	height: 200px;
	margin-top: 20px;
}

.upload_bg .middleportion .MuiOutlinedInput-multiline {
	height: 200px !important;
}

.upload_bg .middleportion .MuiDropzoneArea-root {
	min-height: 200px !important;
}

.rec_ic {
	text-align: center;
	line-height: 200px;
}

.rec_ic .MuiSvgIcon-root {
	color: #267abd;
	font-size: 63px;
	line-height: 213px !important;
}

.upload_bg .MuiFormControlLabel-root span {
	color: #303f9f;
	font-size: 13px;
	font-weight: 600;
}

.upload_bg .MuiDropzoneArea-textContainer p {
	color: #d2d2d2;
	font-size: 16px;
}

.upload_bg .MuiDropzoneArea-textContainer .MuiDropzoneArea-icon {
	color: #267abd;
}

.blankimagesshow {
	text-align: center;
	padding: 2% 0px;
}

.bk_tx {
	font-size: 22px !important;
	font-weight: 600 !important;
	color: #9c9c9c;
}
.bk_tx_1 {
	font-size: 18px !important;
	font-weight: 400 !important;
	color: #9c9c9c;
	margin-bottom: 30px !important;
}
.bk_img {
	height: 40vmin;
	margin-bottom: 30px;
}

/*Audio to Text*/

/* Support start */

.graph {
	padding: 10px !important;
}

.address {
	padding: 20px;
	min-height: 335px;
}
.address .mhead {
	color: #fff;
	font-size: 18px;
	font-weight: 600;
}

.address .shead {
	font-size: 13px;
	text-align: justify;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #fff;
}

.address .geticon {
	position: relative;
	top: 8px;
	margin-right: 14px;
}
/* Support end */

/* Pricing start */
.mainbxpricing {
	padding: 20px;
	margin-bottom: 50px;
}

.firstbx {
	color: #fff;
	padding: 4px 20px;
	text-align: center;
}

.details {
	padding: 20px 4px;
}

.details .detailstext {
	font-size: 12px !important;
	color: #000;
	font-weight: 500 !important;
	margin: 6px 0px;
}

.details .checkicon {
	color: #000;
	position: relative;
	top: 8px;
	margin-right: 16px;
}

/* Pricing End */

/* Dmaas :start */
.dmaasic {
	height: 15vmin;
}

.bulletpara .MuiListItemText-primary {
	font-size: 14px !important;
	text-align: justify;
	line-height: 22px !important;
	color: #242536 !important;
}

/* Dmaas End */
.bread {
	float: right;
}
.bread ol li p {
	font-size: 13px;
	color: #b0b0b0;
	font-weight: 500;
}

.smnm {
	color: #303f9f;
	font-size: 12px !important;
}

.bgnm {
	color: #000;
	font-size: 18px !important;
	font-weight: 600 !important;
}

.bread ol li .main {
	color: #303f9f !important;
	font-weight: 600 !important;
}

.bread ol li .br_ic {
	position: relative !important;
	top: 8px !important;
}

.bread .MuiBreadcrumbs-separator {
	display: none !important;
}

/* Textclassification*/

.whitebx {
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
	background-color: #fff;
	padding: 20px 12px !important;
	border-radius: 4px !important;
	margin-bottom: 50px;
}

.maintabbedsection .MuiTabs-scrollButtonsDesktop {
	display: block !important;
	display: inline-flex !important;
	color: #303f9f !important;
}

.maintabbedsection .tabbedbar {
	background-color: transparent !important;
	box-shadow: none !important;
}

.maintabbedsection .MuiTab-root {
	width: auto !important;
	background: #c8cfd5 !important;
	margin-right: 10px !important;
	color: #85898d !important;
	font-size: 12px !important;
	min-height: auto !important;
	padding: 6px 24px !important;
	min-width: auto !important;
	border-radius: 21px !important;
}

.maintabbedsection .MuiTab-textColorPrimary.Mui-selected {
	color: #fff !important;
	background: #3f51b5 !important;
}

.maintabbedsection .MuiDropzoneArea-textContainer p {
	color: #d2d2d2;
	font-size: 16px;
}

.maintabbedsection .MuiDropzoneArea-textContainer .MuiDropzoneArea-icon {
	color: #267abd;
}

.contentshow {
	height: 300px;
	background-color: #eff6ff;
	margin: 15px 0px;
	padding: 20px;
	border-radius: 4px;
}

.iframe_content {
	height: auto;
	background-color: #eff6ff;
	margin: 15px 0px;
	padding: 20px;
	border-radius: 4px;
}

#iframe {
	padding-top: 2px;
	width: 100%;
	height: 150vh;
}
.contentshow_a {
	height: auto;
	background-color: #eff6ff;
	margin: 15px 0px;
	padding: 20px;
	border-radius: 4px;
}
/* Textclassification End */

/*Data duplication*/
.head1 {
	color: #303f9f !important;
	font-size: 13px !important;
	font-weight: 600 !important;
	text-align: center;
}

.head2 {
	color: #d2d2d2 !important;
	font-size: 13px !important;
	font-weight: 500 !important;
	text-align: center;
}

.feedname .MuiTypography-h6 {
	font-size: 14px !important;
	text-align: left !important;
	color: #303f9f !important;
}

.uploadtext {
	font-size: 14px !important;
	text-align: left !important;
	color: #303f9f !important;
	font-weight: 600 !important;
	margin-bottom: 14px !important;
}

.ext .MuiPaper-elevation2 {
	box-shadow: none !important;
}

.pdf .MuiOutlinedInput-input {
	padding: 12px 14px !important;
}

/*Data end duplication*/

/*DMAAS*/

.ddtab .MuiBox-root-19 {
	padding: 12px !important;
}

.dm_rd {
	margin-bottom: 20px;
}

.dm_rd .MuiFormControlLabel-root span {
	color: #303f9f;
	font-size: 13px;
	font-weight: 600;
}

.topbtpadd {
	padding: 10px 0px;
}

.storageinputsection {
	background-color: #f2f2f2;
	padding: 20px !important;
	margin-bottom: 20px;
	border-radius: 4px;
}

.storageinputsection_a {
	background-color: #e0e9ef;
	padding: 20px !important;
	margin-bottom: 20px;
	border-radius: 4px;
	border: 2px dotted #b0b7bc;
}

.dmt {
	position: relative;
	top: 12px;
}

.dtt .MuiFormControl-marginNormal {
	margin-top: 3px !important;
	margin-bottom: 0px !important;
}

.dtt .MuiInputBase-input {
	font-size: 13px !important;
}

.b_modal .MuiDialog-paperWidthSm {
	min-width: 50% !important;
}

/*DMAAS*/
/* Validate section */
.vd_tp {
	color: #f0f8ff;
}
.vd_tptext {
	color: #3f51b5;
	font-size: 12px !important;
	margin-bottom: 20px !important;
	font-weight: 500 !important;
}

.vd_tptext span {
	font-size: 12px !important;
	margin: 0px 5px;
	font-weight: 500 !important;
	background-color: #3f51b5;
	color: #fff;
	padding: 5px 11px;
	border-radius: 4px;
}

.sh_n .MuiPaper-elevation2 {
	box-shadow: none !important;
}
.mb_n {
	margin-bottom: 0px !important;
}
.tit .MuiToolbar-root {
	display: none;
}
.fl_wd {
	padding: 5px 0px !important;
}

.midsize {
	width: 320px;
	margin: 0 auto;
}

.linemid {
	width: 100%;
	height: 2px;
	background-color: #fb7f2f;
	border-radius: 20px;
	position: relative;
}

.linemid img {
	position: absolute;
	top: -25px;
}

.firstcontent {
	background-color: #d3f0ff;
	padding: 4%;
	border-radius: 6px;
}

.smtextt {
	text-align: center;
	font-size: 18px;
	margin-bottom: 36px;
	font-weight: 600;
}

.content {
	position: relative;
	width: 100%;
	/* height: 100%;
    max-height: 200px; */
	max-width: 300px;
	/* max-height: 400px; */
	margin: auto;
	overflow: hidden;
}

.content .content-overlay {
	background: rgba(46, 61, 153, 0.9);
	/* position: absolute; */
	height: 99%;
	width: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
	-webkit-transition: all 0.4s ease-in-out 0s;
	-moz-transition: all 0.4s ease-in-out 0s;
	transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
	opacity: 1;
}

.content-image {
	width: 100%;
	height: 100%;
}

.content-details {
	position: absolute;
	text-align: center;
	padding-left: 1em;
	padding-right: 1em;
	width: 100%;
	top: 50%;
	left: 50%;
	opacity: 0;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
	top: 50%;
	left: 50%;
	opacity: 1;
}

.content-details .content-title {
	color: #fff;
	font-weight: 600;
	margin-bottom: 2px;
	font-size: 20px;
}

.content-details .content-text {
	color: #fff;
	font-size: 0.8em;
}

.fadeIn-bottom {
	top: 80%;
}

.fadeIn-top {
	top: 20%;
}

.fadeIn-left {
	left: 20%;
}

.fadeIn-right {
	left: 80%;
}

.openic {
	color: #fff !important;
}

.listtext .MuiListItemText-root .MuiListItemText-primary {
	color: #002984;
	font-size: 13px;
	font-weight: 600;
}

/* Validate section end */

/* Media Query start */
@media (max-width: 1270px) {
	.sideicon {
		text-align: left;
	}
}

@media (max-width: 960px) {
	.bannertext {
		margin-top: 12% !important;
	}
	.bannerheading {
		font-size: 40px !important;
		line-height: 44px !important;
		text-align: center !important;
		left: 0px;
	}
}

@media (max-width: 960px) {
	.bannerheading {
		font-size: 30px !important;
		line-height: 34px !important;
		text-align: center !important;
		left: 0px;
	}
}

@media (max-width: 600px) {
	.footer_one_text,
	.footer_two_text {
		text-align: center !important;
	}
	.bread {
		float: none;
	}
}

.App {
	text-align: left;
	background-color: aliceblue !important;
	padding-top: 7%;
	min-height: 100vh;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	width: 40%;
	margin: 0 auto;
}

.router_link {
	text-decoration: none;
}

.addSourceProj {
	box-shadow: none !important;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.MUIDataTableToolbar {
	padding-left: -50px;
}
.bt_button .bt {
	margin-right: 5px;
}

.bt_button .bta {
	margin-bottom: 10px;
}

.alltxfield .MuiFormLabel-root {
	font-size: 12px !important;
}

.alltxfield .MuiInputBase-root {
	font-size: 12px !important;
	color: #000 !important;
}

.alltxfield .MuiFormHelperText-contained {
	margin: 0px !important;
	color: #1930ab;
}

.alltxfield .MuiFilledInput-input {
	padding: 5px !important;
	/* Changed for Select search, if issues, please revert it to 7px */
}

.alltxfield .MuiInputLabel-filled {
	z-index: 1;
	transform: translate(8px, 8px) scale(1);
	pointer-events: none;
}

.alltxfield .MuiInputLabel-filled.MuiInputLabel-shrink {
	transform: translate(12px, 5px) scale(0.75) !important;
	font-size: 13px !important;
	color: #1930ab;
	display: none;
}

.alltxfielda .MuiInputLabel-filled.MuiInputLabel-shrink {
	transform: translate(12px, 5px) scale(0.75) !important;
	font-size: 13px !important;
	color: #1930ab;
	display: block !important;
}

.maindd {
	background-color: #01b4f0 !important;
	color: #fff !important;
}

.company_modal .MuiDialog-paperWidthSm {
	min-width: 80%;
}
